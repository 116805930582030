function delay(n) {
  n = n || 2000
  // Keep official documentation wording, done -> resolve
  // and make it more concise
  return new Promise(done => {
    
    setTimeout(() => {
      done(); 
    }, n)

  })
}


function defaultTransitionOut() {

  fadeOutAnimation = new TimelineMax();

  fadeOutAnimation
    .to($("body"), 0.4,{opacity:0, ease:Power2.easeOut}, 0.2);
}


function defaultTransitionIn() {

  var $el = $("body");

  $(window).scrollTop(0);

  transitionAnimation = new TimelineMax();
    
  transitionAnimation
    .set($el,{opacity:0, visibility:"visible"})
    .to($el, 0.5,{opacity:1, ease:Power2.easeOut})
    .staggerFrom($("[data-slide-in]"),0.6,{opacity:0, x:"103%", ease:Power3.easeOut}, 0.02, 0.3)
    .staggerFrom($("[data-slide-up]"),0.6,{opacity:0, y:50, ease:Power4.easeOut}, 0.1, 0.3)
    .staggerFrom($("[data-fade-in]"),1.1,{opacity:0, ease:Power4.easeInOut}, 0.01, 0.1);
}


barba.init({
  sync: true,
  cacheIgnore: true,
  transitions: [
    {
      name: 'default-transition',
      async leave() {
        const done = this.async();

        hideNavbar();
        defaultTransitionOut();

        await delay(400);
        done();
      },
      async enter() {
        defaultTransitionIn();
      }
    }
  ]
});

navbar();
scrollToggle();


$(document).ready(function(){
  // Add smooth scrolling to all links
  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});


barba.hooks.after((data) => {
  
  navbar();
  scrollToggle();

  // Add smooth scrolling to all links
  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});