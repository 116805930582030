function showNavbar() {
  var toggleMenu = $('.main-nav__toggle');
  var toggleCollapse = $('.main-nav__collapse');
  var menuLinks = $('.main-nav__inner');
  transitionAnimation = new TimelineMax();
        
  transitionAnimation
    .set($("body"), {className:"+=js--nav-expanded"})
    .set(toggleMenu, {className:"+=active"})
    .set(menuLinks,{opacity:1, y:0, rotation:0, transformOrigin:"right top"})
    .to(toggleCollapse,0.5,{opacity:1, ease:Power2.easeIn}, 0.2)
    .staggerFrom(menuLinks,0.4,{opacity:0, y:150, rotation:8, transformOrigin:"right top", ease:Power4.easeInOut}, 0.05, 0.3);
}

function hideNavbar() {
  var toggleMenu = $('.main-nav__toggle');
  var toggleCollapse = $('.main-nav__collapse');
  var menuLinks = $('.main-nav__inner');
  transitionAnimation = new TimelineMax();
        
  transitionAnimation
    .set(toggleMenu, {className:"-=active"})
    .set(menuLinks,{opacity:1, y:0, rotation:0, transformOrigin:"right top"})
    .staggerTo(menuLinks,0.3,{opacity:0, y:-100, rotation:8, transformOrigin:"right top", ease:Power4.easeIn}, 0.05, 0)
    .to(toggleCollapse,0.4,{opacity:0, ease:Power4.easeInOut}, 0.3)
    .set($("body"), {className:"-=js--nav-expanded"});
}

function navbar() {
  $(".main-nav__toggle").click(function() {
    
    if($("body").hasClass("js--nav-expanded")) {
      hideNavbar();

    } else if(!$("body").hasClass("js--nav-expanded")) {
      showNavbar();
    }
  });
}

function scrollToggle() {
  var homepage = $("#hero").length > 0;

  if (homepage) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // build scenes
    new ScrollMagic.Scene({triggerElement: "#hero", triggerHook: 0, offset: -110, duration: $("#hero").outerHeight()})
      .setClassToggle(".menu-icon", "light") // add class toggle
      // .addIndicators()
      .addTo(controller);

    new ScrollMagic.Scene({triggerElement: "#sec2", triggerHook: 0, offset: -110, duration: $("#sec2").outerHeight()})
      .setClassToggle(".menu-icon", "dark") // add class toggle
      // .addIndicators()
      .addTo(controller);

    new ScrollMagic.Scene({triggerElement: "#sec3", triggerHook: 0, offset: -110, duration: $("#sec3").outerHeight()})
      .setClassToggle(".menu-icon", "light") // add class toggle
      // .addIndicators()
      .addTo(controller);

    // new ScrollMagic.Scene({triggerElement: "#sec4", triggerHook: 0, offset: -110, duration: $("#sec4").outerHeight()})
    //   .setClassToggle(".menu-icon", "dark") // add class toggle
    //   // .addIndicators()
    //   .addTo(controller);

    new ScrollMagic.Scene({triggerElement: "#hero", triggerHook: 0, offset: 5, duration: 150})
      // animate color and top border in relation to scroll position
      .setTween(".hero__arrow", {opacity: 0})
      // .addIndicators({name: "2 (duration: 150)"})
      .addTo(controller);

  }
}